import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n'
import { APP_STORE_MODULE_NAME } from '../storeModule';

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {

    // Use toast
    const toast = useToast()
    const refListTable = ref(null)
    const perPage = ref(10)
    const total = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const nameFilter = ref(null)
    const codeFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: total.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, nameFilter, codeFilter], () => {
        refetchData()
    })

    const fetchAllData = (ctx, callback) => {
        store
            .dispatch(`${APP_STORE_MODULE_NAME}/fetchAllData`, {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                name: nameFilter.value,
                code: codeFilter.value,
            })
            .then(response => {
                const { data, pageCount } = response
                callback(data)
                total.value = pageCount
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // Table headers
    const tableColumns = [
        { key: 'barcodeText', label: i18n.t("barcode.text"), sortable: true },
        { key: 'type', label: i18n.t("barcode.type"), sortable: true },
        { key: 'status', label: i18n.t("barcode.status"), sortable: true },
        { key: 'actions', label: i18n.t("actions") },
    ]

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveStatusVariant = status => {
        if (status === false) return 'warning'
        if (status === true) return 'success'
        return 'primary'
    }

    const statusOptions = [
        { label: i18n.t("status.true"), value: true },
        { label: i18n.t("status.false"), value: false },
    ]

    return {
        fetchAllData,
        refetchData,
        tableColumns,
        perPage,
        currentPage,
        total,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,

        //custom properties
        resolveStatusVariant,
        statusOptions,
        // Extra Filters
        statusFilter,
        nameFilter,
        codeFilter,
    }
}
