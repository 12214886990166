<template>
    <div>

        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="modelData === undefined">
            <h4 class="alert-heading">
                {{$t('errorfetchingdata')}}
            </h4>
            <div class="alert-body">
                {{$t('nofoundwiththisid')}}
                <b-link class="alert-link" :to="{ name: `${APP_STORE_MODULE_NAME}-list` }">
                    {{$t('checklist')}}
                </b-link>
                {{$t('forotherrecords')}}

            </div>
        </b-alert>

        <template v-if="modelData">
            <!-- First Row -->
            <view-info-card :model-data="modelData" />
        </template>

    </div>
</template>

<script>
    import store from '@/store'
    import router from '@/router'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { BRow, BCol, BAlert, BLink, } from 'bootstrap-vue'
    import storeModule, { APP_STORE_MODULE_NAME } from '../storeModule'
    import ViewInfoCard from './ViewInfoCard.vue'

    export default {
        components: {
            BRow,
            BCol,
            BAlert,
            BLink,

            // Local Components
            ViewInfoCard

        },
        props: {
            id: String,
        },
        setup(props) {
            const modelData = ref(null)

            // Register module
            if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
            })

            store.dispatch(`${APP_STORE_MODULE_NAME}/fetchData`, { id: router.currentRoute.params.id || props.id })
                .then(response => { modelData.value = response.data })
                .catch(error => {
                    if (error.response.status === 404) {
                        modelData.value = undefined
                    }
                })

            return {
                modelData,
                APP_STORE_MODULE_NAME,
            }
        },
    }
</script>

<style>
</style>
